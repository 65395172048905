import axios from 'axios'
import { serialize } from '@/util/util'
import { Base64 } from 'js-base64';
import { Message } from 'element-ui'
import router from '@/router'

// const baseURL = "http://31094ld435.wicp.vip/"
// const baseURL = "http://192.168.5.11:8201/"
// const baseURL = "http://31094ld435.wicp.vip/"
// const baseURL = "http://j6608ucs.dongtaiyuming.net/";
// const baseURL = "http://119.96.235.187:9985/demo/"
// const baseURL = "http://31094ld435.wicp.vip:12934/";
// const baseURL = 'http://192.168.0.150:9999/demo';


export function request(config){
  const baseURL  = window.localStorage.getItem('ipUrl') + 'demo'
  const instance = axios.create({
     baseURL,
    timeout:5000,
    // method,
  })


  //请求拦截
  instance.interceptors.request.use(config => {
    let token = localStorage.getItem('access_token')
    config.headers.isToken = false;
    config.headers['TENANT-ID'] = 1;
    config.headers.Authorization = 'Basic bWluYXBwOm1pbmFwcA=='

   
    if(token){
      // config.headers.Authorization = 'Basic '+token;
      // config.headers.Authorization = 'Bearer e0c4b13c-afdb-40e1-8b02-7ae272d7a81f';
      

      config.headers.Authorization = 'Bearer '+token;
    }
    if (config.method === 'post') {
      config.data = serialize(config.data)
      delete config.data.serialize
    }
    
    return config
  },err =>{
    // console.log(111);
    console.log(err);
  })

  // 响应拦截
  instance.interceptors.response.use(res =>{
  
    
    if (res.status === 424) {
      Message.error('令牌状态已过期，请点击重新登录');
      localStorage.removeItem('access_token');
      router.push({
        path:'/',
      })
      return
    }
    if(res.status != 200){
        Message.error();
        return false;
    }
    
    return res.data
  },err => {
    console.log(err);
  })
  
  let method = config.method ? config.method.toLocaleLowerCase() : 'get';
  // console.log(method);
  
  if (method == 'get') {
    config.params = config.data
  } else {
    // config.data = config.data
  }

  // console.log(config);
  // 发送真正的网络请求
  let res = instance(config);
  // console.log(res);return false;
  return res;


}

export function onlinePreview(url){
  // url = "http://139.198.175.122:9000/xzyw/2021123118221063.docx";
  // url = "http://139.198.175.122:9000/xzyw/2021123118221063.docx";
  return '/onlinePreview?url='+encodeURIComponent(Base64.encodeURI(url));
}

